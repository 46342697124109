import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { Settings } from '../'
import './SettingsPanel.css';

export const SettingsPanel = (props) => {
  const {
    eventTooltip,
    handleSchedulerTimeFormat,
    scheduleObj,
    setEventTooltip,
    settings,
    sidebarRef,
    showBackdrop,
    suppressTooltip,
    timeFormat,
    toggleSidebar,
  } = props;

  const { MajorSlots, MinorSlots, TimeFormats, Tooltips, WeekDays } = Settings;

  const handleTooltipChange = (args) => {
    args.value === 'Off' ? setEventTooltip(false) : setEventTooltip(true);
  }

  return (
    <SidebarComponent
      className='e-sidebar-component'
      closeOnDocumentClick={true}
      id='apiSidebar'
      position={'Right'}
      ref={sidebarRef}
      showBackdrop={showBackdrop}
      target='.content-wrapper'
      type={'Over'}
      width='250px'
    >
      <div className='e-sidebar-header' style={{ display: 'flex', alignItems: 'center', position: 'sticky' }}>
        <div style={{ display: 'flex', justifyContent: 'center', flex: 3, fontSize: '14px' }} className='e-sidebar-title'>Settings</div>
        <ButtonComponent id='arrowRightBtn' cssClass='e-inherit' iconCss='e-icons e-arrow-right' onClick={toggleSidebar} isPrimary />
      </div>
      <div className='e-sidebar-sub-title'>
        <div className='control-panel e-css'>
          {settings.includes('FIRST DAY OF WEEK') && 
            (<div className='col-row'>
            <div className='col-left'>
              <label style={{ lineHeight: '34px', margin: '0' }}>First Day of Week</label>
            </div>
            <div className='col-right'>
              <DropDownListComponent id='weekFirstDay' dataSource={WeekDays} fields={{ text: 'text', value: 'value' }} value={1} popupHeight={400} change={(args) => { scheduleObj.current.firstDayOfWeek = args.value; }} />
            </div>
          </div>)
          }
          <div className='col-row'>
            <div className='col-left'>
              <label style={{ lineHeight: '34px', margin: '0' }}>Slot Duration</label>
            </div>
            <div className='col-right'>
              <DropDownListComponent id='slotDuration' dataSource={MajorSlots} fields={{ text: 'Name', value: 'Value' }} value={60} popupHeight={150} change={(args) => { scheduleObj.current.timeScale.interval = args.value; }} />
            </div>
          </div>
          <div className='col-row'>
            <div className='col-left'>
              <label style={{ lineHeight: '34px', margin: '0' }}>Slot Interval</label>
            </div>
            <div className='col-right'>
              <DropDownListComponent id='slotInterval' dataSource={MinorSlots} value={2} popupHeight={150} change={(args) => { scheduleObj.current.timeScale.slotCount = args.value; }} />
            </div>
          </div>
          {settings.includes('TIME FORMAT') && <div className='col-row'>
            <div className='col-left'>
              <label style={{ lineHeight: '34px', margin: '0' }}>Time Format</label>
            </div>
            <div className='col-right'>
              <DropDownListComponent id='timeFormat' dataSource={TimeFormats} fields={{ text: 'Name', value: 'Value' }} value={timeFormat} popupHeight={150} change={handleSchedulerTimeFormat} />
            </div>
          </div>}
          {!suppressTooltip && <div className='col-row'>
            <div className='col-left'>
              <label style={{ lineHeight: '34px', margin: '0' }}>Tooltip</label>
            </div>
            <div className='col-right'>
              <DropDownListComponent id='tooltip' dataSource={Tooltips} fields={{ text: 'Name', value: 'Value' }} value={eventTooltip ? 'On' : 'Off'} popupHeight={150} change={handleTooltipChange} />
            </div>
          </div>}
        </div>
      </div>
    </SidebarComponent>
  );
}