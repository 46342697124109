import React, { useCallback } from 'react'
import Header from 'shared/components/header/Header';
import Button from 'shared/components/button/Button';
import Select from 'shared/components/select/Select'
import { GetChannelLookupAPI, GetBreakChannelTemplateAPI } from 'api';
import './BreakPatterns.css';
import { DataGrid } from 'op2mise-react-widgets';
import EditIcon from 'assets/icons/EditIcon.svg';
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import moment from 'moment';
import data from './break-patterns.json';
import BreakPatternEditor from './BreakPatternEditor';

const BreakPatterns = () => {
  const [channelsList, setChannelsList] = React.useState([]);
  const [selectedChannel, setSelectedChannel] = React.useState(0);

  const [breakPatterns, setBreakPatterns] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [mode, setMode] = React.useState('view'); // view, edit, new
  const [versionName, setVersionName] = React.useState('');
  const [channelInfo, setChannelInfo] = React.useState({
    "channelId": 0,
    "channelName": null
  });

  const getChannelsAPI = () => {
    GetChannelLookupAPI({
      onSuccess: (res) => {
        if (res.length) {
          setChannelsList(res)
          setSelectedChannel(res[0].channelId);
          setChannelInfo(res[0]);
        }
      },
      setLoader: setIsLoading,
    });
  };


  // Format created date to DD/MM/YYYY HH:mm
  const formattedDate = (params) =>
    params.data.createdDate !== null
      ? moment(params.data.createdDate).format('DD/MM/YYYY HH:mm')
      : '';

  // Column definitions for the data grid
  const columnDefinitions = React.useMemo(
    () => [
      {
        field: 'version',
        headerName: 'Version',
        headerTooltip: 'Version',
        sort: 'asc'
      },
      {
        field: 'createdDate',
        headerName: 'Date Created',
        headerTooltip: 'Date Created',
        valueGetter: formattedDate,
      },
      {
        field: 'createdBy',
        headerName: 'Created by',
        headerTooltip: 'Created by',
      },
      {
        field: 'status',
        headerName: 'Status',
        headerTooltip: 'Status',
      },
      {
        field: 'action',
        headerName: ' ',
        suppressHeaderMenuButton: true,
        width: 48,
        cellClass: 'ag-text-align-center suppress-cell-selection-border',
        suppressNavigable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (params) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <img
              src={EditIcon}
              alt="Edit version"
              title="Edit version"
              onClick={() => { setMode('edit'); setSelectedRow(params.data); setVersionName(params.data.version) }}
              style={{
                marginTop: '5px',
                cursor: 'pointer',
              }}
            />
          </div>
        ),
      },
    ],
    []
  );

  const fetchBreakPatterns = (channelId) => {
    GetBreakChannelTemplateAPI({
      queryParams: { channelId },
      onSuccess: (res) => {
        setBreakPatterns(res);
      },
      setLoader: setIsLoading,
    });
  };

  const props = React.useMemo(() => ({
    mode: mode,
    onClose: () => setMode('view'),
    onImport: () => { },
    selectedRow: selectedRow,
  }), [mode, selectedRow]);

  // Returns all fields from columnDefinition except the action column
  const headerFields = columnDefinitions
    .filter((column) => column.field !== 'action')
    .map((column) => column.field);

  React.useEffect(() => {
    getChannelsAPI();
  }, []);

  React.useEffect(() => {
    const { channelId } = channelInfo;
    if (channelId) {
      fetchBreakPatterns(channelId);
    }
  }, [channelInfo]);

  return (
    <div>
      <Header title={
        <div>
          <span>Break Patterns</span>
          {versionName.length > 0 && mode === 'edit' ? <span style={{ color: "#6D6D73" }}> | {versionName}</span> : null}

        </div>
      } childComponent={(
        <div className='d-flex align-items-center gap-4'>
          {/* Channels Selection/Dropdown */}
          {mode === 'view' ? (
            <div className='break-banner-channel-selector'>
              <span className='break-banner-channel-label'>Channel:</span>
              <div>
                {/** Re-locate this re-usable component to `op2mise-react-widgets */}
                <Select
                  text={channelsList.find(channel => channel.channelId === selectedChannel)?.channelName ?? 'Please select a channel'}
                  list={channelsList}
                  onSelect={(args) => {
                    setSelectedChannel(args.channelId),
                      setChannelInfo(args)
                  }}
                  width='200px'
                />
              </div>
            </div>
          ) : null}

          {/* Additional CTAs here */}
          <div
            style={{
              width: 'auto',
            }}
          >
            <Button
              text='New Version'
              onClick={() => {
                setMode('new')
                setSelectedRow({
                  version: '',
                  status: 'Inactive',
                })
              }}
              hidden={mode !== 'view'}
              disabled={channelsList.length === 0}
            />
          </div>
        </div>
      )} />
      <div>
        {isLoading ? (
          <div style={{ height: 'calc(100vh - 123px)' }}>
            <BootstrapSpinner pushTop="-9px" />
          </div>
        )
          : channelsList.length ? mode === 'view'
            ? (
              <DataGrid
                columns={columnDefinitions}
                customVerbiage="No versions found"
                enableMultipleRowSelection={false}
                gridHeightBuffer={177}
                resizable
                rows={breakPatterns}
                sharedColumnProperties={{
                  cellStyle: { fields: headerFields, value: { textAlign: 'left' } },
                  flex: {
                    fields: headerFields,
                    value: 1,
                  },
                  sortable: {
                    fields: headerFields,
                    value: true,
                  },
                  filter: {
                    fields: headerFields,
                    value: true,
                  },
                }}
                showAlternativeRowHighlight
                suppressRowClickSelection={true}
              />
            )
            : (<BreakPatternEditor {...props} />)
            : null}
      </div>
    </div>
  )
}

export default BreakPatterns