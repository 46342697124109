import styles from './EmailTrigger.module.css';

import Button from '../../shared/components/button/Button';
import Input from '../../shared/components/input/Input';
import TextArea from '../../shared/components/text-area/TextArea';

const GeneratingSchedulesComponent = ({
  errorFields,
  parameters,
  onChange,
  onSendaTestEmail,
  onUpdate,
  isUpdating,
}) => {
  return (
    <div className={styles.generatingSchedules}>
      <p className={styles.title}>Trigger - Generating schedules</p>

      <div className={styles.form}>
        <Input
          label="Subject"
          name="subject"
          value={parameters.subject}
          errorMessage={errorFields.subjectErrorMessage}
          showErrorMessage={errorFields.subjectState}
          maxLength="255"
          onChange={onChange}
          isRequired
        />
        {errorFields.subjectState && <div style={{ marginTop: '-15px' }} />}
        <br />
        <Input
          label="Recipient(s)"
          name="recipients"
          value={parameters.recipients}
          errorMessage={errorFields.recipientsErrorMessage}
          showErrorMessage={errorFields.recipientsState}
          onChange={onChange}
          isRequired
        />
        {errorFields.recipientsState && <div style={{ marginTop: '-15px' }} />}
        <br />
        <Input
          label="Attachment(s)"
          name="attachments"
          value={parameters.attachments}
          onChange={onChange}
          maxLength="100"
        />
        <div style={{ marginTop: '10px' }}>
          <label className={styles.label}>Body</label>
          <br />
          <TextArea
            value={parameters.emailTemplate}
            name="emailTemplate"
            onChange={onChange}
          />
        </div>
      </div>

      <div className={styles.footer} style={{ marginTop: '5px' }}>
        <Button text="Send a test email" onClick={onSendaTestEmail} />
        <Button text="Update" onClick={onUpdate} isLoading={isUpdating} />
      </div>
    </div>
  );
};

export default GeneratingSchedulesComponent;
