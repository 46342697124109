import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import styles from './styles.module.css';

function Checkbox({
  label,
  shape,
  disabled,
  active,
  onCheck,
  checkboxClassName,
  fontClassName,
  tooltip,
  checkIconClass,
}) {
  const [isActive, setIsActive] = useState(active);

  // styles
  const form =
    shape === 'box' ? styles.box : shape === 'circle' && styles.circle;
  const isChecked = isActive ? styles.checked : styles.notChecked;
  const isDisabled = disabled && styles.disabled;
  // eslint-disable-next-line max-len
  const isDisabledChecked =
    disabled && (isActive ? styles.disabledChecked : styles.disabledNotChecked);

  const executeOnCheck = () => {
    // setIsActive(active === true ? true : !isActive);
    // onCheck(active === true ? true : !isActive);
    setIsActive(!active);
    onCheck(!active);
  };

  const onKeyDownExecuteOnCheck = (e) => {
    if (e.key === 'Enter') executeOnCheck();
  };

  useEffect(() => {
    setIsActive(active && active);
  }, [active]);

  return (
    <div className={styles.checkboxDiv} title={tooltip}>
      <div
        className={`${form} ${isChecked} ${isDisabled} ${isDisabledChecked} ${checkboxClassName}`}
        onClick={executeOnCheck}
        onKeyDown={onKeyDownExecuteOnCheck}
        role="presentation"
        tabIndex={0}
      >
        <span className={`${styles.checkIcon} ${checkIconClass}`} />
      </div>

      {label && (
        <div className={`${styles.label} ${fontClassName}`}>{label}</div>
      )}
    </div>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string,
  shape: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onCheck: PropTypes.func.isRequired,
  style: PropTypes.shape(PropTypes.any),
  tooltip: PropTypes.string,
};

Checkbox.defaultProps = {
  label: null,
  shape: 'box',
  disabled: false,
  active: false,
  style: {},
};

export default Checkbox;
