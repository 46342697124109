/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable indent */
import React from 'react';
import { Scheduler } from 'op2mise-react-widgets';
import { Internationalization } from '@syncfusion/ej2-base';
import moment from 'moment';
import { ImportGradeArchitectureAPI, GetGradeArchitectureAPI } from "api";
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import { formatDateWithMoment } from 'utils';
import { ImportComponent } from './modals/ImportComponent';
import { getMondayOfTheWeek } from 'utils';
import { addDaysToDate } from 'utils';

export function CAGrading({ schedulerProps, channelInfo, calculateDates }) {
  const instance = new Internationalization();

  const [schedules, setSchedules] = React.useState([{}]);
  const [openForm, setOpenForm] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const { channelId, channelName } = channelInfo;

  // Getting date range of today and tomorrow
  // NOTE: Use addDaysToDate from date.utils.js
  const tomorrow = moment()
    .add(1, 'days')
    .toDate();

  const period = `${formatDateWithMoment(
    new Date().toDateString(),
  )} - ${formatDateWithMoment(tomorrow)}`;

  // Date Header Template
  const dateHeaderTemplate = (args) => {
    const day = instance.formatDate(args.date, { skeleton: 'E' });
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className="e-header-day"
          style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
        >
          {day}
        </div>
      </div>
    );
  };

  const eventFields = (args) => ({
    Grade: args.grade,
    Id: args.id,
    ProgramType: args.programmeType,
    StartTime: calculateDates(args.dayOfWeek, 1, args.startTime, args.endTime, { startDate: getMondayOfTheWeek(new Date()), endDate: addDaysToDate(getMondayOfTheWeek(new Date()), 6) })['startTime'],
    EndTime: calculateDates(args.dayOfWeek, 1, args.startTime, args.endTime, { startDate: getMondayOfTheWeek(new Date()), endDate: addDaysToDate(getMondayOfTheWeek(new Date()), 6) })['endTime'],
  });

  const eventTemplate = (args) => {
    const { Grade } = args;
    return (
      <div
        className={`e-schedule-custom-event e-schedule-grading e-schedule-grade-${Grade}`}
      >
        <div className="e-schedule-custom-event-title e-schedule-grading-title">
          {Grade}
        </div>
      </div>
    );
  };

  const props = React.useMemo(
    () => ({
      ...schedulerProps,
      dataSource: schedules,
      eventLookupList: 'grading',
      loading: isLoading,
      suppressExporting: true,
      suppressQuickInfo: true,
      suppressContextMenu: true,
      suppressTooltip: true,
      dateHeaderTemplate,
      eventTemplate,
      eventFields,
      onImport: () => {
        // Put your import logic here
        setOpenForm('IMPORT');
      },
      settings: ['SLOT DURATION', 'SLOT INTERVAL'],
      timeoutDelay: 800,
    }),
    [schedules],
  );

  const cleanOutInvalidGrades = (data) => {
    return data.filter((schedule) => schedule.grade !== null && schedule.grade !== undefined && schedule.grade !== 0);
  }

  React.useEffect(() => {
    if (channelId || isDirty) {
      GetGradeArchitectureAPI({
        queryParams: { channelId },
        onSuccess: (response) => {
          const validGrades = cleanOutInvalidGrades(response);
          setSchedules({ grading: [...validGrades] });
          setIsDirty(false);
        },
        setLoader: setIsLoading,
      });
    }
  }, [channelId, isDirty]);

  return (
    <>
      {' '}
      {openForm === 'IMPORT' && (
        <ImportComponent
          closeModal={() => setOpenForm('')}
          setIsDirty={setIsDirty}
          setIsLoading={setIsLoading}
          scheduleInfo={{ ...channelInfo, period }}
          schedulerData={{
            scheduleInfo: {
              ...channelInfo,
              period,
            },
          }}
          sampleTemplate="gradeImportTemplate"
          handleOnImportApi={ImportGradeArchitectureAPI}
          importResultColumns={[{
            field: 'dayOfweek',
            headerText: 'Day of Week',
          }, {
            field: 'startTime',
            headerText: 'Start Time',
          }, {
            field: 'endTime',
            headerText: 'End Time',
          }, {
            field: 'grade',
            headerText: 'Grade',
          }]}
          importResultFileTitle="grade_import_result"
        />
      )}
      {isLoading
        ? <div style={{ height: 'calc(100vh - 100px)' }}><BootstrapSpinner /></div>
        : channelId === 0 && !channelName ? null : <Scheduler {...props} />}
    </>
  );
}

export default CAGrading;
//
