import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styles from './profile.module.css';
import chevIcon from '../../assets/icons/Chevron-Right.png';
import defaultAvatar from '../../assets/icons/DefaultAvatar.png';
import signOutIcon from '../../assets/icons/Sign-Out.png';
import profileIcon from '../../assets/icons/Profile-icon.svg';
import authService from '../../core/auth.service';
import useStore from 'store/AccountStore';
import { chevLeftCircleIcon } from 'assets';

const { forwardRef, useImperativeHandle } = React;
const ProfileComponent = forwardRef(
  (
    {
      toggleSidebar,
      hideProfileLink,
      callbackUserLogout,
      isProfileUpdated,
      setIsProfileUpdated,
    },
    ref
  ) => {
    const { user, setUser } = useStore((state) => state);
    const nameRef = useRef(null);
    const [sidebarState, setSidebarState] = useState(false);
    const [userIdentity, setUserIdentity] = useState({
      firstName: '',
      lastName: '',
      jobTitle: '',
      profilePic: defaultAvatar,
    });
    const [hideProfileLinkState, setHideProfileLinkState] = useState(true);
    const [profileTitle, setProfileTitle] = useState(null);
    const [jobTitle, setJobTitle] = useState(null);

    const getUserInfo = () => {
      setIsProfileUpdated(false);
      setUserIdentity({
        firstName: user.firstName,
        lastName: user.lastName,
        jobTitle: user.jobTitle,
        profilePic: user.profilePic,
      });
    };

    const getBrowserWidth = () =>
      Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );

    const toggleSidebarState = () => {
      setSidebarState(!sidebarState);
      toggleSidebar(!sidebarState);
    };

    const handleLogout = () => {
      setUser({});
      authService.logout();
      callbackUserLogout();
    };

    const toggleSetHideProfileLink = () => {
      hideProfileLink(!hideProfileLinkState);
      setHideProfileLinkState(!hideProfileLinkState);
    };

    const getProfileTItle = () => {
      if (nameRef.current) {
        return nameRef.current.offsetWidth &&
          nameRef.current.offsetWidth != null &&
          nameRef.current.offsetWidth < nameRef.current.scrollWidth
          ? `${userIdentity.firstName} ${userIdentity.lastName}`
          : null;
      }
      return null;
    };

    const getJobTItle = () => {
      if (nameRef.current) {
        return nameRef.current.offsetWidth &&
          nameRef.current.offsetWidth != null &&
          nameRef.current.offsetWidth < nameRef.current.scrollWidth
          ? `${userIdentity.jobTitle}`
          : null;
      }
      return null;
    };

    const onToggleSidebarByKeyboard = (e) => {
      if (e.key === 'Enter') toggleSidebarState();
    };

    useImperativeHandle(ref, () => ({
      updateSidebarProfile(id) {
        getUserInfo(id);
      },
    }));

    useEffect(() => {
      if (isProfileUpdated) {
        getUserInfo();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isProfileUpdated]);

    useEffect(() => {
      const handleResize = () => {
        if (getBrowserWidth() < 800) {
          setSidebarState(true);
        } else {
          setSidebarState(false);
        }
      };

      window.addEventListener('resize', handleResize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    useEffect(() => {
      if (getBrowserWidth() < 800) {
        setSidebarState(true);
      } else {
        setSidebarState(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      getUserInfo();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
      setProfileTitle(getProfileTItle());
      setJobTitle(getJobTItle());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameRef, userIdentity]);

    return (
      <>
        <div
          className="position-relative w-100"
          style={{ marginBottom: '33px' }}
        >
          <img
            className={`
            ${styles.chevLeftCircleIcon}
            ${sidebarState ? styles.chevLeftRotateIcon : ''}
          `}
            style={{
              marginRight:
                !hideProfileLinkState && sidebarState
                  ? '-29px'
                  : sidebarState
                  ? '-31px'
                  : '-37px',
              marginTop: hideProfileLinkState
                ? sidebarState
                  ? '-73px'
                  : '-54px'
                : '30px',

              zIndex: 1,
            }}
            src={chevLeftCircleIcon}
            alt="hamburgerIcon"
            onClick={toggleSidebarState}
            onKeyDown={onToggleSidebarByKeyboard}
            role="presentation"
            title={sidebarState ? 'Expand Menu' : 'Collapse Menu'}
          />
          <div
            className="mb-3 px-3 d-flex position-relative"
            style={{ cursor: 'pointer' }}
            onClick={toggleSetHideProfileLink}
            title={
              hideProfileLinkState ? 'Show options/sign out' : 'Hide options'
            }
          >
            <img
              src={
                userIdentity.profilePic
                  ? userIdentity.profilePic
                  : defaultAvatar
              }
              className={`${styles.vaiProfilePhoto} ${
                sidebarState ? styles.vaiShrinkPhoto : null
              }`}
              alt="ProfilePhoto"
              style={{
                marginLeft: sidebarState ? '2px' : null,
                marginBottom:
                  sidebarState && !hideProfileLinkState ? '19px' : null,
              }}
            />
            {!sidebarState && (
              <>
                <div
                  style={{
                    overflowX: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    marginLeft: '15px',
                    width: '100px',
                  }}
                >
                  <p
                    className={styles.vaiUsername}
                    title={`${userIdentity.firstName} ${userIdentity.lastName}`}
                  >
                    {`${userIdentity.firstName} ${userIdentity.lastName}`}
                    <br />{' '}
                    <span
                      className={styles.vaiUserTitle}
                      title={userIdentity.jobTitle}
                    >
                      {userIdentity.jobTitle || '-'}
                    </span>
                  </p>
                </div>

                <span style={{ paddingLeft: '28px' }}>
                  <img
                    src={chevIcon}
                    alt="ChevUpIcon"
                    className={styles.vaiChevUp}
                  />
                  <img
                    src={chevIcon}
                    alt="ChevDownIcon"
                    className={styles.vaiChevDown}
                  />
                </span>
              </>
            )}
          </div>
        </div>

        <div
          className={`${sidebarState ? 'px-4' : 'px-3'} mx-1 position-relative`}
          style={{
            cursor: 'pointer',
            marginTop: hideProfileLinkState ? '10px' : '10px',
            display: hideProfileLinkState && 'none',
            marginBottom: hideProfileLinkState ? '-10px' : '25px',
          }}
          to={`${process.env.REACT_APP_SUBFOLDER}/profile`}
        >
          <NavLink
            title="Profile"
            className={(navData) =>
              `${
                navData.isActive ? styles.activeLink : styles.link
              } nav-item nav-link px-0 py-2 position-relative`
            }
            to={`${process.env.REACT_APP_SUBFOLDER}/profile`}
          >
            <img
              src={profileIcon}
              alt="profileIcon"
              style={{ marginLeft: '-4px' }}
            />
            <span
              className={styles.linkText}
              style={{ display: sidebarState ? 'none' : null }}
            >
              Profile
            </span>
          </NavLink>
        </div>
        <div
          title="Sign Out"
          className={`mx-1 position-relative`}
          style={{
            cursor: 'pointer',
            marginTop: hideProfileLinkState ? '10px' : '-10px',
            display: hideProfileLinkState && 'none',
            paddingLeft: sidebarState ? '21px' : '13px',
          }}
          onClick={handleLogout}
          onKeyDown={handleLogout}
          role="button"
          tabIndex={0}
        >
          <img src={signOutIcon} alt="Horizontal-Ellipsis" />
          {!sidebarState && (
            <span className={styles.vaiSignOutText}>Sign Out</span>
          )}
        </div>
        <br />
        <br />
      </>
    );
  }
);
ProfileComponent.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  hideProfileLink: PropTypes.func.isRequired,
  callbackUserLogout: PropTypes.func.isRequired,
};
ProfileComponent.displayName = 'ProfileComponent';
export default ProfileComponent;
