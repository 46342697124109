import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './Loading.module.css';

export default function ImportLoadingModalComponent({ headerComponent }) {
  return (
    <Modal show={true} centered size="lg">
      {headerComponent}
      <div className={styles.divLoading}>
        <p className={styles.pLoading}>
          <i
            className={`${styles.faCircleNotchSpin} fas fa-circle-notch fa-spin`}
          />
        </p>
      </div>
    </Modal>
  );
}
