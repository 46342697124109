import React, { useState, useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import GeneratedScheduleBannerComponent from './generated-schedule-banner/GeneratedScheduleBannerComponent';
import './full-calendar.css';
import styles from '../Schedule.module.css';
import ScheduleService from '../ScheduleService';
import DateService from '../../../core/date.service';
import AuthService from '../../../core/auth.service';
import useNotification from 'store/NotificationStore';

function GeneratedScheduleFullComponent() {
  const navigate = useNavigate();
  const { success } = useNotification((state) => state);
  const [scheduleID, setScheduleID] = useState(1);
  const [schedules, setSchedules] = useState([]);
  const [startDate, setStartDate] = useState();
  const calendarRef = useRef();
  const [scheduleInfo, setSCheduleInfo] = useState({
    channelID: 0,
    channelName: '',
    month: '',
    year: '',
  });
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [disabledStatistics, setDisabledStatistics] = useState(true);
  const [disabledExport, setDisabledExport] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [scheduleScores, setScheduleScores] = useState('');
  const [validRange, setValidRange] = useState({});
  const [loading, setLoading] = useState(true);
  const [organisation, setOrganisation] = useState('');
  // const moment = require('moment');
  const hiddenDays = []; // [0, 1, 2]
  const [filterSchedule, setFilterSchedule] = useState({});

  const getGeneratedScheduleDetails = (id, filter) => {
    setSchedules(id);
    ScheduleService.getScheduleDetails(
      id,
      filter.channelId,
      filter.month,
      filter.year
    ).then((response) => {
      setSchedules(response.data.scheduleDetails);
      setStartDate(response.data.startDate);
      setSCheduleInfo({
        channelID: response.data.channelID,
        channelName: response.data.channelName,
        month: response.data.month,
        year: response.data.year,
      });
      setDisabledSubmit(response.data.disableSubmission);
      setValidRange({
        start: response.data.startDate,
        end: response.data.endDate,
      });
      setDisabledExport(response.data.disableExport);
      setDisabledStatistics(response.data.disableStatistics);

      setScheduleScores(response.data.scoreLabel);
      setOrganisation(response.data.organisationName);
      setLoading(false);
      if (calendarRef != null) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(response.data.startDate);
      }
    });
  };
  const isEmptyOrSpaces = (str) => str === null || str.match(/^ *$/) !== null;

  const renderEventContent = (eventInfo) => {
    if (eventInfo.event.allDay) {
      const scoreDetails = eventInfo.event.extendedProps;
      return (
        <div style={{ padding: '10px' }}>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>Weighted:</b>
            <b className={styles.allDayScore}>{scoreDetails.weighted}</b>
          </div>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>Unweighted:</b>
            <b className={styles.allDayScore}>{scoreDetails.unweighted}</b>
          </div>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>US Box Office:</b>
            <b className={styles.allDayScore}>{scoreDetails.usBoxOffice}</b>
          </div>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>UK Box Office:</b>
            <b className={styles.allDayScore}>{scoreDetails.ukBoxOffice}</b>
          </div>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>Youth of Asset:</b>
            <b className={styles.allDayScore}>{scoreDetails.youth}</b>
          </div>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>Average Break:</b>
            <b className={styles.allDayScore}>{scoreDetails.break}</b>
          </div>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>Strength of Primetime:</b>
            <b className={styles.allDayScore}>{scoreDetails.primetime}</b>
          </div>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>Neatness:</b>
            <b className={styles.allDayScore}>{scoreDetails.neatness}</b>
          </div>
        </div>
      );
    }
    const { title } = eventInfo.event;
    const { grade } = eventInfo.event.extendedProps;
    const { certificate } = eventInfo.event.extendedProps;
    const pin =
      eventInfo.event.extendedProps.pin == null
        ? ''
        : eventInfo.event.extendedProps.pin;
    const timeStamp = DateService.toTimeFormat(
      eventInfo.event.extendedProps.start
    );
    const { duration } = eventInfo.event.extendedProps;
    const { genre } = eventInfo.event.extendedProps;
    let gradeCertificateString = grade;

    if (isEmptyOrSpaces(gradeCertificateString)) {
      gradeCertificateString = certificate;
    } else if (!isEmptyOrSpaces(certificate)) {
      gradeCertificateString = `${gradeCertificateString}, ${certificate}`;
    }

    if (isEmptyOrSpaces(gradeCertificateString)) gradeCertificateString = pin;
    else if (!isEmptyOrSpaces(pin))
      gradeCertificateString = `${gradeCertificateString}, ${pin}`;

    const durationInt =
      (eventInfo.event.end.getTime() - eventInfo.event.start.getTime()) /
      1000 /
      60 /
      60;

    return (
      <div
        title={`${timeStamp} (${duration}) ${genre}\n${title}\n${grade}\n${certificate}`}
        className={styles.divParent}
      >
        <div className={styles.divChild}>
          <small
            className={
              durationInt >= 1
                ? styles.lblTimeStampCalendar
                : styles.lblTimeStampCalendarNoWrap
            }
          >
            {timeStamp} ({duration}){genre}
          </small>
          <br />
          <b style={{ color: '#0c212b' }} className={styles.lblTitleCalendar}>
            {title}
          </b>
          {/* <br /> */}
          <small
            style={{ color: '#787E82', fontSize: '12px', display: 'block' }}
          >
            {gradeCertificateString}
          </small>
        </div>
      </div>
    );
  };

  const submitSchedule = () => {
    const submission = {
      channelID: scheduleInfo.channelID,
      scheduleID,
    };
    setDisabledSubmit(true);
    ScheduleService.submitSchedule(submission).then(() => {
      success('Schedule submitted');
    });
  };

  const callbackSchedule = () => {
    const scheduledata = filterSchedule;
    navigate(`${process.env.REACT_APP_SUBFOLDER}/schedule`, {
      state: { scheduledata, scheduleInfo },
    });
  };

  const handleExport = () => {
    if (!isExporting) {
      const queryString = `?ScheduleId=${scheduleID}&ChannelId=${scheduleInfo.channelID}`;
      setIsExporting(true);
      ScheduleService.exportSubmissions(queryString)
        .then((response) => {
          const blob = new Blob([response.data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(
            blob,
            `${organisation}_op2mise_generated_schedule_export_${
              scheduleInfo.channelName
            }_${scheduleInfo.month}_${
              scheduleInfo.year
            }_${DateService.toExportFormat(new Date())}.zip`
          );
        })
        .finally(() => {
          setIsExporting(false);
        });
    }
  };

  const handleExportStatistics = () => {
    if (!isExporting) {
      const queryString = `?ScheduleId=${scheduleID}&ChannelId=${scheduleInfo.channelID}`;
      setIsExporting(true);
      ScheduleService.exportStatistics(queryString)
        .then((response) => {
          const blob = new Blob([response.data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(
            blob,
            `${organisation}_op2mise_generated_statistics_export_${
              scheduleInfo.channelName
            }_${scheduleInfo.month}_${
              scheduleInfo.year
            }_${DateService.toExportFormat(new Date())}.zip`
          );
        })
        .finally(() => {
          setIsExporting(false);
        });
    }
  };

  useEffect(() => {
    const scheduledata = AuthService.getSelectedSchedule();
    setScheduleID(scheduledata.data.id);
    getGeneratedScheduleDetails(scheduledata.data.id, scheduledata.filter);
    setFilterSchedule(scheduledata.filter);
    //   authService.getSelectedSchedule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <GeneratedScheduleBannerComponent
        componentName="Schedules"
        scheduleDetails={scheduleInfo}
      />

      <hr style={{ background: '#c8c8c8' }} />
      {loading ? (
        <div
          className={`${styles.loadingHeight}`}
          style={{
            fontSize: '50px',
            color: '#13ad83',
            backgroundColor: 'transparent',
            position: 'relative',
          }}
        >
          <i
            className="fas fa-circle-notch fa-spin mr-1"
            style={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
              position: 'absolute',
              height: '50px',
              width: '50px',
            }}
          />
        </div>
      ) : (
        <>
          <small
            className={styles.divScheduleScores}
            title={`ID ${scheduleID} ${scheduleScores}`}
          >
            <b>
              ID
              {scheduleID}
            </b>{' '}
            {scheduleScores}
          </small>
          <FullCalendar
            ref={calendarRef}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              momentPlugin,
            ]}
            initialDate={startDate}
            initialView="timeGridWeek"
            // height={'calc(100vh - 150px)'}
            contentHeight="calc(100vh - 230px)"
            allDayDefault={false}
            editable={false}
            selectable={false}
            selectMirror
            dayMaxEvents
            // weekends={false}
            slotMinTime="06:00:00"
            slotMaxTime="29:59:00"
            allDaySlot={false}
            // allDayText={'ID ' + scheduleID}
            eventContent={renderEventContent}
            events={schedules}
            validRange={validRange}
            slotLabelFormat={['H:mm']}
            dayHeaderContent={(args) =>
              moment(args.date).format('ddd DD/MM/YYYY')
            }
            hiddenDays={hiddenDays}
            // datesSet={function (dateInfo) {
            //   setTimeout(() => {
            //     // var currentStartFormat = moment(dateInfo.start).format(
            //     //   'dddd DD/MM/YYYY'
            //     // )
            //     // var validStartFormat = moment(validRange.start).format(
            //     //   'dddd DD/MM/YYYY'
            //     // )
            //     // if (
            //     //   currentStartFormat == validStartFormat &&
            //     //   hiddenDays.length == 0
            //     // ) {
            //     //   var day = moment(dateInfo.start).day()
            //     //   hideDateStart(day)
            //     // } else if (
            //     //   moment(dateInfo.end).isSameOrAfter(moment(validRange.end)) &&
            //     //   hiddenDays.length == 0
            //     // ) {
            //     //   var dayEnd = moment(validRange.end).day()
            //     //   hideDateEnd(dayEnd)
            //     // } else if (
            //     //   currentStartFormat !== validStartFormat &&
            //     //   !moment(dateInfo.end).isSameOrAfter(moment(validRange.end)) &&
            //     //   hiddenDays.length > 0
            //     // ) {
            //     //   removeHiddenDay()
            //     // }
            //   }, 50);
            // }}
            // timeFormat='H:mm'
            // viewDidMount={({ el }) => {
            //   el.querySelector('.fc-timegrid-axis-frame').innerHTML =
            //     'ID ' + scheduleID
            //   // el.find('.fc-timegrid-axis').html(
            //   //   '<span>ID ' + scheduleID + '</span>'
            //   // )
            // }}
          />
          {/* </div> */}
          <div style={{ marginTop: '15px', float: 'right' }}>
            <button
              className={styles.cancelButton}
              onClick={callbackSchedule}
              type="button"
            >
              Close
            </button>
            <button
              className={styles.saveButton}
              style={{ marginLeft: '10px' }}
              onClick={handleExportStatistics}
              disabled={disabledStatistics}
              type="button"
            >
              Statistics
            </button>
            <button
              className={styles.saveButton}
              style={{ marginLeft: '10px' }}
              onClick={handleExport}
              disabled={disabledExport}
              type="button"
            >
              Export
            </button>
            <button
              className={styles.saveButton}
              onClick={submitSchedule}
              disabled={disabledSubmit}
              style={{
                marginLeft: '10px',
              }}
              type="button"
            >
              Submit
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default GeneratedScheduleFullComponent;
