import { useState, useEffect, Fragment } from 'react';
import { Paper } from '@mui/material';
import { GetChannelLookupAPI } from 'api';
import { DataGrid } from 'op2mise-react-widgets';
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import Select from 'shared/components/select/Select';
import ReactHlsPlayer from 'react-hls-player';
import './Playlist.css';

const Playlist = () => {

  const [channelsList, setChannelsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState(1);

  const columnDefinition = [
    {field: 'startTime', headerName: 'start time'},
    {field: 'endTime', headerName: 'end time'},
    {field: 'duration', headerName: 'duration'},
    {field: 'assertId', headerName: 'assert id'},
    {field: 'title', headerName: 'title'},
    {field: 'segment', headerName: 'segment'},
    {field: 'mediaType', headerName: 'media type'},
    {field: 'type', headerName: 'type'},
  ];

  const rows = [
    { 
        startTime: '04/24/2024 16:00', 
        endTime: '04/24/2024 16:00', 
        duration: '01:30', 
        assertId: 123, 
        title: 'Example Title 1', 
        segment: 'Sample Segment String ', 
        mediaType: 'Video', 
        type: 'Type A' 
    },
    { 
        startTime: '04/24/2024 16:00', 
        endTime: '04/24/2024 16:00', 
        duration: '01:00', 
        assertId: 456, 
        title: 'Example Title 2', 
        segment: 'Sample Segment String ', 
        mediaType: 'Audio', 
        type: 'Type B' 
    },
    { 
        startTime: '04/24/2024 16:00', 
        endTime: '04/24/2024 16:00', 
        duration: '01:30', 
        assertId: 789, 
        title: 'Example Title 3', 
        segment: 'Sample Segment String ', 
        mediaType: 'Text', 
        type: 'Type C' 
    },
    // Add more objects as needed
  ];

  for (let i = 0; i < 22; i++) {
    const clone = JSON.parse(JSON.stringify(rows[i % rows.length]));
    rows.push(clone);
  }

  const getChannelsAPI = () => {
    GetChannelLookupAPI({
      onSuccess: (res) => setChannelsList(res),
      setLoader: setIsLoading,
    });
  };

  // Render Side-Effects
  useEffect(() => {
    // Calls get-channels lookup list API
    getChannelsAPI();
  }, []);

  return (
    <div>
      <div className='playlist-banner'>
        <div className='playlist-banner-header-text'>Playlist</div>
        {channelsList.length ? (
          <div className='playlist-banner-channel-selector'>
            <span className='playlist-banner-channel-label'>Channels:</span>  
            <div>
              {/** Re-locate this re-usable component to `op2mise-react-widgets */}
              <Select
                text={channelsList.find(channel => channel.channelId === selectedChannel).channelName}
                list={channelsList}
                onSelect={(args) => setSelectedChannel(args.channelId)}
                width={175}
                noSearch
              />
            </div>
          </div>
        ) : null}
      </div>
      <hr style={{background: 'rgb(200, 200, 200'}} />
      <div className='playlist-content'>
        { isLoading ? <div style={{height: 'calc(100vh - 100px)'}}><BootstrapSpinner /></div> : (
        <div className='playlist-content-wrapper'>
          <div style={{flex: 1}}>
            <DataGrid
              columns={columnDefinition}
              rows={rows}
              sharedColumnProperties={{
                flex: {
                  fields: ['startTime', 'endTime', 'duration', 'assertId', 'title', 'segment', 'mediaType', 'type'],
                  value: 1,
                },
              }}
              resizable
              gridHeight={100}
            />
          </div>
          <div style={{flex: 1, padding: '0px 0px 0px 20px'}}>
            <Paper elevation={3} square sx={{padding: '50px 0px', display: 'block', alignItems: 'center', background: 'var(--op2mise-color-dark-cyan)'}}>
              <div></div>
              <div className='playlist-player-wrapper' style={{height: '430px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {/* <span className='e-icons e-play' style={{color: '#FFF', fontSize: '16rem'}}></span> */}
                  <ReactHlsPlayer
                    src="https://c4da7516d609d1a79f5550ea917bea0e.p05sqb.channel-assembly.mediatailor.us-west-2.amazonaws.com/v1/channel/op2misetest005/fast-on-aws-12-Hls.m3u8"
                    autoPlay={true}
                    controls={true}
                    width="99.9%"
                    height="auto"
                  />
              </div>
              <div></div>
            </Paper>
          </div>
        </div>
        )}
      </div>
    </div>
  )
}

export default Playlist;