const StringService = {
  getNumbersOnly(string) {
    const stringArray = string.split('');
    const result = stringArray.filter(
      (char) => (!isNaN(char) && !isNaN(parseFloat(char))) || char === ':'
    );
    return result.join('');
  },
  isEmptyOrSpaces(str) {
    if (str === undefined) {
      return true;
    }
    return str === null || str.match(/^ *$/) !== null;
  },
  padLeft(string, pad, requiredLength) {
    if (string.length < requiredLength) {
      return pad + string;
    }
    return string;
  },
};

export default StringService;
