import axios from 'axios';

const hostUrl = process.env.REACT_APP_HOST_URL;

const EmailTriggerService = {
  testAuthentication(credentials) {
    const url = hostUrl + 'EmailTrigger/TestAuthentication';
    return axios.post(url, credentials);
  },

  updateEmailSettings(credentials) {
    const url = hostUrl + 'EmailTrigger/UpdateEmailSettings';
    return axios.put(url, credentials);
  },

  getEmailSettings() {
    const url = hostUrl + 'EmailTrigger/GetEmailSettings';
    return axios.get(url);
  },
  
  getOrganisationLookup() {
    const url = hostUrl + 'Organisation/GetOrganisatonsLookup';
    return axios.get(url);
  },

  getChannelsByOrganisationLookup(organisationId) {
    const url = hostUrl + 'Channel/OrganisationChannelLookup?organisationId=' + organisationId;
    return axios.get(url);
  },

  getEmailTemplate(generating) {
    const url = hostUrl + 'EmailTrigger/GetEmailTemplate?generating=' + generating;
    return axios.get(url);
  },

  updateEmailTemplate(json) {
    const url = hostUrl + 'EmailTrigger/UpdateEmailTemplate';
    return axios.put(url, json);
  },

  sendTestEmail(json) {
    const url = hostUrl + 'EmailTrigger/SendTestEmailCommand';
    return axios.post(url, json);
  },
};

export default EmailTriggerService;
