import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';
import Button from '../button/Button';

function Header({
  title,
  buttonText,
  onClickButton,
  childComponent,
}) {
  const noButton = typeof onClickButton !== 'function' && !buttonText;

  return (
    <div className={styles.banner}>
      <div className={styles.title} title={title}>{title}</div>

      <div className={styles.actionWrapper}>
        <div className={styles.floatRight}>
          {!noButton && <Button text={buttonText} onClick={onClickButton} />}
          {childComponent}
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  componentName: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  onClickButton: PropTypes.func,
  childComponent: PropTypes.node,
};

Header.defaultProps = {
  componentName: '',
  buttonText: '',
  onClickButton: null,
  childComponent: null
};

export default Header;
