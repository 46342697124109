import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import Button from '../button/Button';
import Searchbar from '../searchbar/SearchBar';

function Select({
  label,
  height,
  text,
  list,
  onSelect,
  width,
  disabled,
  noSearch,
  noDataVerbiage,
  errorMessage,
  isRequired,
}) {
  const optionRef = useRef();
  const [data, setData] = useState();
  const [showOptionList, setShowOptionList] = useState(false);

  const onClickShowOptionList = () => setShowOptionList(true);

  const selectItem = (value) => {
    if (typeof onSelect === 'function') {
      onSelect(value);
      setShowOptionList(false);
    }
  };

  const onSearch = (searchKey) => {
    setData([
      ...list.filter((item) => item[Object.keys(item)[1]].toLowerCase().includes(searchKey.toLowerCase())),
    ]);
  };

  const triggerSelectItem = (e, data) => {
    if (e.key === 'Enter') selectItem(data);
  };

  useEffect(() => {
    setData(list || []);
  }, [list]);

  useEffect(() => {
    const onClickBackdrop = (e) => {
      if (!optionRef.current?.contains(e.target)) {
        setShowOptionList(false);
      }
    };

    document.addEventListener('mousedown', onClickBackdrop);
    return () => document.removeEventListener('mousedown', onClickBackdrop);
  }, [optionRef]);

  return (
    <div className={styles.select} style={{ width }}>
      {label && <div className={styles.label}>{label} {isRequired && <span style={{ color: '#FF0000' }}>*</span>}</div>}

      <Button
        text={text}
        style={{ width }}
        onClick={onClickShowOptionList}
        disabled={disabled}
        caretOn
      />

      <div
        ref={optionRef}
        className={styles.optionListWrapper}
        style={{ width }}
        hidden={!showOptionList}
      >
        {!noSearch && (
          <div className={styles.searchWrapper}>
            <Searchbar onSearch={onSearch} smallInput />
          </div>
        )}

        {data?.length > 0 ? (
          <div className={styles.optionList} style={{ maxHeight: height, width, marginTop: noSearch ? '-1px' : '40px' }}>
            {data.map((object) => {
              const id = object[Object.keys(object)[0]];
              const name = object[Object.keys(object)[1]];

              return (
                <div
                  key={id}
                  className={styles.optionItem}
                  title={name}
                  onClick={() => selectItem(object)}
                  onKeyDown={(e) => triggerSelectItem(e, object)}
                  tabIndex={0}
                >
                  {name}
                </div>
              );
            })}
          </div>
        ) : (
          <div className={styles.noOptionsWrapper} style={{ width, marginTop: noSearch ? '-1px' : '40px', borderTop: noSearch ? 'none' : '1px solid #d3d2d2' }}>
            <p>{noDataVerbiage || 'No match found'}</p>
          </div>
        )}
      </div>

      {errorMessage && <small className={styles.errorMessage}>{errorMessage}</small>}
    </div>
  );
}

Select.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  onSelect: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool,
  noSearch: PropTypes.bool,
  noDataVerbiage: PropTypes.string,
};

Select.defaultProps = {
  label: null,
  list: null,
  onSelect: null,
  width: '70px',
  text: '',
  height: '125px',
  disabled: false,
  noSearch: false,
  noDataVerbiage: '',
};

export default Select;
