import axios from 'axios';
import EventType from '../enums/EventTypeEnum';
import jwt_decode from 'jwt-decode';

const AuthService = {
  isAuthenticated() {   
    const identity = JSON.parse(sessionStorage.getItem('userIdentity'));
    
    if (!identity || !identity.token) {
      return false;
    }

    const token = jwt_decode(identity.token);        
    const isTokenExpired = token.exp * 1000 < new Date().getTime();

    if (isTokenExpired) {      
      sessionStorage.removeItem('userIdentity');
      localStorage.setItem(EventType.Flush_Credentials, Date.now().toString());
      localStorage.removeItem(EventType.Flush_Credentials);    
    }    

    return !isTokenExpired;
  },  

  setUserIdentity(userIdentity) {
    sessionStorage.setItem('userIdentity', JSON.stringify(userIdentity));
    window.localStorage.setItem(EventType.Share_Credentials, JSON.stringify(userIdentity));
    window.localStorage.removeItem(EventType.Share_Credentials);
  },

  getUserIdentity() {
    const userIdentity = JSON.parse(sessionStorage.getItem('userIdentity'));
    return userIdentity;
  },

  login(loginInfo) {
    const url = `${process.env.REACT_APP_HOST_URL}Auth/Login`;
    return axios.post(url, loginInfo);
  },

  logout() {
    const userIdentity = JSON.parse(sessionStorage.getItem('userIdentity'));
    if (userIdentity && userIdentity.userId !== null) {
      const url = `${process.env.REACT_APP_HOST_URL}auth/LogoutUser?ID=${userIdentity.userId}`;
      axios.post(url).then(() => {        
        sessionStorage.removeItem('userIdentity');
      });      
    }    

    localStorage.setItem(EventType.Flush_Credentials, Date.now().toString());
    localStorage.removeItem(EventType.Flush_Credentials);    
  },

  getToken() {
    const userIdentity = JSON.parse(sessionStorage.getItem('userIdentity'));
    if (!userIdentity) return null;
    return userIdentity.token;
  },

  getRememberedCredentials() {
    const credentials = JSON.parse(localStorage.getItem('credentials'));
    if (credentials) {
      return credentials;
    }
    return [];
  },

  getRememberedCredential(email) {
    const credentials = this.getRememberedCredentials();
    const credential = credentials.find((o) => o.email === email);

    return credential;
  },

  rememberCredential(credential) {
    let credentials = JSON.parse(localStorage.getItem('credentials'));
    const duplicate = this.getRememberedCredential(credential.email);

    if (duplicate) {
      const first = credential.email;
      credentials.sort((x, y) => {
        let retVal = 0;
        if (x.email === first) {
          retVal = -1;
        } else if (y.email === first) {
          retVal = 1;
        }
        return retVal;
      });

      localStorage.setItem('credentials', JSON.stringify(credentials));

      return false;
    }

    if (credentials) {
      credentials.unshift(credential);
      localStorage.setItem('credentials', JSON.stringify(credentials));
    } else {
      credentials = [];
      credentials.push(credential);
      localStorage.setItem('credentials', JSON.stringify(credentials));
    }
    return true;
  },

  removeCredential(credential) {
    const credentials = JSON.parse(localStorage.getItem('credentials'));

    if (credentials) {
      const filteredCredentials = [
        ...credentials.filter((o) => o.email !== credential.email),
      ];
      // prettier-ignore
      if (filteredCredentials.length > 0) {
        localStorage.setItem(
          'credentials',
          JSON.stringify(filteredCredentials),
        );
      } else {
        localStorage.removeItem('credentials');
      }
    } else {
      return true;
    }
    return true;
  },

  isOrganisationalAdmin() {
    const user = JSON.parse(sessionStorage.getItem('userIdentity'));

    return user.organisationId;
  },

  setSelectedSchedule(selectedSchedule) {
    localStorage.setItem('selectedSchedule', JSON.stringify(selectedSchedule));
  },

  getSelectedSchedule() {
    // prettier-ignore
    const selectedSchedule = JSON.parse(
      localStorage.getItem('selectedSchedule'),
    );
    return selectedSchedule;
  },
  setSelectedSubmission(selectedSubmission) {
    localStorage.setItem(
      'selectedSubmission',
      JSON.stringify(selectedSubmission)
    );
  },

  getSelectedSubmission() {
    // prettier-ignore
    const selectedSubmission = JSON.parse(
      localStorage.getItem('selectedSubmission'),
    );
    return selectedSubmission;
  },
};
export default AuthService;
