import PropTypes from 'prop-types';

import styles from './styles.module.css';
import { blocksShuffleSpinner } from 'shared/assets';

function BootstrapSpinner({ size, icon, pushTop }) {
    const imgStyle = {
        height: size,
        filter: 'invert(32%) sepia(97%) saturate(4268%) hue-rotate(154deg) brightness(90%) contrast(101%)'
    };

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <div
                className={styles.bootstrapSpinner}
                style={{ width: size, height: size, top: pushTop ? `calc(50% - ${pushTop})` : '50%' }}>
                {icon ? (
                    <img
                        src={icon}
                        alt=""
                        style={imgStyle}
                    />
                ) : (
                    <img
                        src={blocksShuffleSpinner}
                        alt=""
                        style={imgStyle}
                    />
                )}
            </div>
        </div>
    );
};

BootstrapSpinner.propTypes = {
    size: PropTypes.string,
};
  
BootstrapSpinner.defaultProps = {
    size: '40px',
};

export default BootstrapSpinner;