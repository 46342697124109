import axios from 'axios';

const ScheduleService = {
  getChannels() {
    const url = `${process.env.REACT_APP_HOST_URL}Channel/GetChannelLookup`;
    return axios.get(url);
  },

  getSchedules(querystring) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/GetGeneratedScores${querystring}`;
    return axios.get(url);
  },

  getScheduleDetails(scheduleId, channeId, month, year) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/GetGeneratedScheduleDetails/${scheduleId}?ChannelId=${channeId}&Month=${month}&Year=${year}`;
    return axios.get(url);
  },

  getChannelScoring(channelId) {
    const url = `${process.env.REACT_APP_HOST_URL}Channel/GetChannelScoring/${channelId}`;
    return axios.get(url);
  },

  generateSchedules(params) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/TriggerGenerateSchedule/`;
    return axios.post(url, params);
  },

  submitSchedule(params) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/SubmitSchedule/`;
    return axios.post(url, params);
  },

  exportSubmissions(parameters) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/ExportSchedule${parameters}`;
    return axios.get(url, { responseType: 'arraybuffer' });
  },

  getScheduleSession() {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/GetScheduleSession`;
    return axios.get(url);
  },

  saveScheduleSession(json) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/AddScheduleSession${json}`;
    return axios.post(url, json);
  },

  exportStatistics(parameters) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/ExportStatistics${parameters}`;
    return axios.get(url, { responseType: 'arraybuffer' });
  },

  getCalendarDetails(scheduleId, channeId, month, year, clockStart) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/GetCalendarDetails/${scheduleId}?ChannelId=${channeId}`;
    return axios.get(url);
  },

  getSchedulePrograms(querystring, filter, period, titleId) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/GetSchedulePrograms${querystring}&channelId=${filter.channelId}&start=${period.start}&end=${period.end}&titleId=${titleId}`;
    return axios.post(url);
  },

  getTitleByID(id, channelId) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/GetTitleDetails?titleId=${id}&channelId=${channelId}`;
    return axios.get(url);
  },

  saveSchedule(json) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/SaveSchedule`;
    return axios.post(url, json);
  },

  updateSchedule(json) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/UpdateSchedule`;
    return axios.put(url, json);
  },
  getTitleGroups() {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/GetTitleGroups`;
    return axios.get(url);
  },
  getTitleGroupPrograms(querystring, period, filter) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/GetTitleGroupPrograms${querystring}&channelId=${filter.channelId}&start=${period.start}&end=${period.end}`;
    return axios.get(url);
  },
  uploadScheduleFile(
    formData,
    fileExtension,
    period,
    filter,
    overwrite,
    totalSlots
  ) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/ImportSchedule?fileExtension=${fileExtension}&channelId=${filter.channelId}&start=${period.start}&end=${period.end}&overwrite=${overwrite}&totalSlots=${totalSlots}`;
    return axios.post(url, formData);
  },
  deleteSchedule(json) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/DeleteSchedule`;
    return axios.post(url, json);
  },
  saveUserSession(json) {
    const url = `${process.env.REACT_APP_HOST_URL}User/SaveUserSessionCommand`;
    return axios.post(url, json);
  },
  getUserSession() {
    const url = `${process.env.REACT_APP_HOST_URL}User/GetUserSessionCommand`;
    return axios.get(url);
  },

  getScheduleIDCommand(json) {
    const url = `${process.env.REACT_APP_HOST_URL}Schedule/GetScheduleIDCommand`;
    return axios.post(url, json);
  },
};

export default ScheduleService;
