import moment from 'moment';

const DateService = {
  toLocalFormat(isoDate) {
    const result = moment(isoDate)
      .local()
      .format('DD/MM/YYYY HH:mm');
    return result;
  },

  toFilterByLastSeen(isoDate) {
    const stillUtc = moment.utc(isoDate).toDate();
    const result = moment(stillUtc)
      .local()
      .format('MM/DD/YYYY');
    return result;
  },

  toDateFormat(isoDate) {
    const result = moment(isoDate).format('MM/DD/YYYY');
    return result;
  },

  toLocalDateOnlyFormat(date) {
    const result = new Date(date.toDateString());
    return result;
  },

  toIsoWithoutFormat(stringDate) {
    return moment(stringDate).utc();
  },

  toIsoFormat(stringDate) {
    const s = moment(stringDate)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    return s;
  },

  toIsoFilterFormat(stringDate) {
    const s = moment(stringDate)
      .utc()
      .format('DD-MM-YYYY HH:mm:ss');
    return s;
  },

  toExportFormat(date) {
    const result = moment(date).format('MMDDYYYY');
    return result;
  },

  toDayOfWeekFormat(isoDate) {
    const result = moment(isoDate).format('dddd, DD MMMM YYYY');
    return result;
  },

  toTimeFormat(stringDate) {
    const s = moment(stringDate).format('HH:mm');
    return s;
  },

  getMonthDayRange(monthName) {
    const start = moment()
      .month(monthName)
      .startOf('month')
      .toDate();
    const end = moment()
      .month(monthName)
      .endOf('month')
      .toDate();

    return {
      start,
      end,
    };
  },

  toInputDateFormat(stringDate) {
    const s = moment(stringDate).format('yyyy-MM-DD');
    return s;
  },
  addHours(date, hours) {
    const s = moment(date).add(hours, 'hours');
    return s;
  },
  addMinutes(date, minutes) {
    const s = moment(date).add(minutes, 'minutes');
    return s;
  },
  toIsoDateFormat(stringDate) {
    const s = moment(stringDate).format('YYYY-MM-DD');
    return s;
  },
  addDays(date, days) {
    const s = moment(date).add(days, 'days');
    return s;
  },

  toLocalDMYFormat(isoDate) {
    const localTime = moment.utc(isoDate).toDate();
    const result = moment(localTime).format('DD/MM/YYYY');
    return result;
  },
  toExportExcelFormat(date) {
    const result = moment(date).format('DDMMYYYY');
    return result;
  },
  stringToUTCDate(dateString) {
    const dateSplit = dateString.split('/');
    const utcDate = new Date(
      Date.UTC(
        parseInt(dateSplit[2]),
        parseInt(dateSplit[1]) - 1,
        parseInt(dateSplit[0]),
        23,
        59
      )
    );
    return utcDate;
  },
};

export default DateService;
