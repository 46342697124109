import { Internationalization } from '@syncfusion/ej2-base';
import Playlist from 'assets/icons/Playlist.svg';
import NewPlaylist from 'assets/icons/create-playlist.svg';
import { getMonthFromDate } from 'utils';

export const DateHeaderTemplate = ({ args, toggleInterstitialSidebar, onPlaylistClick, icon }) => {
  let instance = new Internationalization();

  const month = getMonthFromDate(args.date);
  const date = instance.formatDate(args.date, { skeleton: 'd' });
  const day = instance.formatDate(args.date, { skeleton: 'E' });
  const fullDate = `${date} ${month}, ${day}`

  const displayIcon = () =>{
    switch(icon){
      case 'new':
        return <img src={NewPlaylist} alt='create-playlist-icon' />;
      case 'playlist':
        return <img src={Playlist} alt='playlist-icon' />;
      default:
        return null;
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <div className='e-header-day' style={{flex: 1, display: 'flex', justifyContent: 'flex-start', color: 'var(--op2mise-color-dove-gray)'}}>{fullDate}</div>
      <div
        className='e-header-day'
        style={{
          flex: 1, 
          display: 'flex', 
          justifyContent: 'flex-end',
          cursor: 'pointer',
          fontWeight: 500,
          fontSize: '14px'
        }}
        onClick={() => onPlaylistClick && onPlaylistClick(args)}
      >
        <img src={Playlist} alt='playlist-icon' title={icon === 'new' ? 'Create Playlist' : 'Playlist'} />
        </div>
    </div>
  );
};