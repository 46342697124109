import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import styles from './Modal.module.css';

import Button from '../../../../shared/components/button/Button';

function ImportConfirmationModalComponent({ show, onHide, onProceed, message }) {
  return (
    <Modal show={show} centered size="md">
      <div className={styles.body} style={{ textAlign: 'center' }}>
        <div>
          <p className={styles.description}>
            {message ?? `This channel already have existing business rules defined.
            Importing will overwrite all existing custom business rules and values of
            the channel.`}
          </p>

          <p className={styles.confirmationText}>
            Are you sure you want to proceed?
          </p>
        </div>

        <div style={{ marginTop: '30px' }}>

          <Button
            text="Cancel"
            defaultBtn
            onClick={onHide}
          />
          <Button text="Proceed" onClick={onProceed} />
        </div>
      </div>
    </Modal>
  );
}
ImportConfirmationModalComponent.propTypes = {
  onProceed: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
  message: PropTypes.string,
};

ImportConfirmationModalComponent.defaultProps = {
  message: null,
};
ImportConfirmationModalComponent.defaultProps = {
  show: false,
};
ImportConfirmationModalComponent.displayName = 'ImportConfirmationModalComponent';

export default ImportConfirmationModalComponent;
