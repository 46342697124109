import React, { useState, useEffect } from 'react';

import styles from './EmailTrigger.module.css';
import eyeIcon from '../../assets/icons/Eye.png';
import eyeOnIcon from '../../assets/icons/Eye-On.png';

import Input from '../../shared/components/input/Input';
import Button from '../../shared/components/button/Button';
import EmailTriggerService from './EmailTriggerService';
import useNotification from 'store/NotificationStore';

const AuthenticationComponent = ({}) => {
  const { success } = useNotification((state) => state);
  const [showPassword, setShowPassword] = useState(false);
  const [parameters, setParameters] = useState({});
  const [isTesting, setIsTesting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [authenticationResult, setAuthenticationResult] = useState({
    state: '',
    message: '',
  });
  const isSuccess = authenticationResult.state === 'success';
  const isFail = authenticationResult.state === 'failed';

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const onEnterTogglePassword = (e) => {
    if (e.key === 'Enter') togglePassword();
  };

  const onChangeSetParameters = (e) => {
    setParameters({ ...parameters, [e.target.name]: e.target.value });
    setAuthenticationResult({
      state: '',
      message: '',
    });
  };

  const handleTestAuthentication = async () => {
    setIsTesting(true);
    try {
      await EmailTriggerService.testAuthentication(parameters);
      setAuthenticationResult({
        state: 'success',
        message: '',
      });
    } catch (error) {
      setAuthenticationResult({
        state: 'failed',
        message:
          'Authentication failed. Please check if you provided the correct username and password',
      });
    } finally {
      setIsTesting(false);
    }
  };

  const handleUpdateEmailSettings = async () => {
    setIsUpdating(true);
    try {
      await EmailTriggerService.updateEmailSettings(parameters);
      success('Outgoing mail server account successfully updated');
    } catch (error) {
    } finally {
      setIsUpdating(false);
    }
  };

  const handleGetEmailSettings = async () => {
    try {
      var response = await EmailTriggerService.getEmailSettings();
      setParameters(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetEmailSettings();
  }, []);

  return (
    <div className={styles.authentication}>
      <p className={styles.title}>
        Outgoing mail server account and authentication
      </p>

      <div className={styles.form}>
        <Input
          label="Username"
          placeHolder="Enter username"
          name="username"
          value={parameters.username}
          onChange={onChangeSetParameters}
          isRequired
        />

        <div className={styles.inputForm}>
          <div className={styles.inputLabel}>
            Password <span style={{ color: '#FF0000' }}>*</span>
          </div>
          <div className="d-flex" style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
              <input
                className={styles.passwordInputField}
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter password"
                name="password"
                autoComplete="off"
                value={parameters.password}
                onChange={onChangeSetParameters}
              />
            </div>
            <div className={styles.eyeIcon}>
              {showPassword ? (
                <img
                  style={{ cursor: 'pointer' }}
                  src={eyeIcon}
                  alt="EyeIcon"
                  onClick={togglePassword}
                  onKeyDown={onEnterTogglePassword}
                  role="presentation"
                  tabIndex={0}
                  title="Hide password"
                />
              ) : (
                <img
                  style={{
                    cursor: 'pointer',
                    height: '12.03px',
                    width: '15.03px',
                  }}
                  src={eyeOnIcon}
                  alt="EyeOnIcon"
                  onClick={togglePassword}
                  onKeyDown={onEnterTogglePassword}
                  role="presentation"
                  tabIndex={0}
                  title="Show password"
                />
              )}
            </div>
          </div>
        </div>

        {isFail && (
          <p style={{ color: '#FF0000', fontSize: '12px', marginTop: '6px' }}>
            {authenticationResult.message}
          </p>
        )}

        <div
          className={styles.footer}
          style={{ marginTop: isFail ? '22px' : '46px' }}
        >
          <Button
            text={
              isSuccess ? 'Successfully Authenticated!' : 'Test Authentication'
            }
            style={!isSuccess && { backgroundColor: '#FF0000', width: '210px' }}
            onClick={handleTestAuthentication}
            isLoading={isTesting}
            disabled={isSuccess}
          />
          <Button
            text="Update"
            onClick={handleUpdateEmailSettings}
            disabled={!isSuccess}
            isLoading={isUpdating}
          />
        </div>
      </div>
    </div>
  );
};

export default AuthenticationComponent;
