import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

const useNotificationStore = createWithEqualityFn((set) => ({
  message: '',
  success: (data) => set(() => ({ message: data, showNotification: true })),
  danger: (data) => set(() => ({ message: data, showNotification: true })),
  showNotification: false,
  handleConfirm: () => set(() => ({ message: '', showNotification: false })),
}));

const useNotification = (callback) => useNotificationStore(callback, shallow);

export default useNotification;
