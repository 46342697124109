import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';
import Checkbox from '../../components/checkbox/Checkbox';
import caretDown from '../../assets/icons/caret-down.svg';

function SelectComponent({
  label,
  text,
  list,
  onSelect,
  onMultiSelect,
  predefinedSelected,
  width,
  disabled,
  withSearch = false,
  buttonStyle,
  disabledStyle2,
  optionStyle,
  height,
}) {
  const optionRef = useRef();
  const [data, setData] = useState();
  const [showOptionList, setShowOptionList] = useState(false);
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [renderTimes, setRenderTimes] = useState(0);

  useEffect(() => {
    if (list !== null) setData(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (renderTimes <= 1) setSelectedItems(predefinedSelected);
    setRenderTimes(renderTimes + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predefinedSelected]);

  useEffect(() => {
    const backdropClicked = (e) => {
      if (optionRef.current && !optionRef.current.contains(e.target))
        setShowOptionList(false);
    };

    document.addEventListener('mousedown', backdropClicked);
    return () => document.removeEventListener('mousedown', backdropClicked);
  }, [optionRef]);

  const toggleOptionList = () => {
    setShowOptionList(true);
  };

  const selectItem = (value) => {
    if (typeof onSelect === 'function' && typeof onMultiSelect !== 'function') {
      onSelect(value);
      setShowOptionList(false);
    } else {
      const selectedIds = selectedItems.map(
        (item) => item[Object.keys(item)[0]]
      );

      if (selectedIds.includes(value[Object.keys(value)[0]])) {
        const remainingItems = selectedItems.filter(
          (item) => item[Object.keys(item)[0]] !== value[Object.keys(value)[0]]
        );

        onMultiSelect(remainingItems);
        setSelectedItems(remainingItems);
      } else {
        onMultiSelect([...selectedItems, value]);
        setSelectedItems([...selectedItems, value]);
      }
    }
  };

  const onChangeSetSearchKey = (e) => {
    setSearchKey(e.target.value);
  };

  const executeSearch = () => {
    setSearchIsActive(true);
    setData([
      ...list.filter((item) =>
        item[Object.keys(item)[1]]
          .toLowerCase()
          .includes(searchKey.toLowerCase())
      ),
    ]);
  };

  const executeClearSearch = () => {
    setSearchKey('');
    setSearchIsActive(false);
    setData(list);
  };

  const executeSearchOnEnterKeydown = (e) => {
    if (e.key === 'Enter' && searchKey.length > 0) {
      executeSearch();
    }
  };

  return (
    <div className={styles.select}>
      {label && <div className={styles.label}>{label}</div>}

      {text && (
        <button
          className={`
            ${styles.selectButton}
            ${disabled && disabledStyle2 && styles.disabledStyle2}
          `}
          style={{ width, ...buttonStyle }}
          onClick={toggleOptionList}
          disabled={disabled}
          type="button"
        >
          <span>{text}</span>
          <img src={caretDown} className={styles.caretDown} title="Show more" />
        </button>
      )}

      {showOptionList && (
        <div
          ref={optionRef}
          style={{ position: 'absolute', width }}
          className={`${optionStyle}`}
        >
          <div
            className={styles.searchInputGroup}
            style={{ width }}
            hidden={!withSearch}
          >
            <input
              className={styles.searchInput}
              type="text"
              placeholder="Search"
              value={searchKey}
              onChange={onChangeSetSearchKey}
              onKeyDown={executeSearchOnEnterKeydown}
              disabled={searchIsActive}
            />

            <div style={{ backgroundColor: searchIsActive && '#e9ecef' }}>
              {searchIsActive ? (
                <i
                  className={styles.closeIcon}
                  onClick={executeClearSearch}
                  onKeyDown={executeClearSearch}
                  role="presentation"
                  title="Clear"
                />
              ) : (
                <i
                  className={styles.searchIcon}
                  onClick={executeSearch}
                  onKeyDown={executeSearch}
                  role="presentation"
                  title="Search"
                />
              )}
            </div>
          </div>
          <div
            className={styles.optionList}
            style={{
              width,
              height: 'auto',
              maxHeight: height ? height : '205px',
              overflowX: 'hidden',
            }}
          >
            {data.length > 0 ? (
              data.map((object) => {
                const id = object[Object.keys(object)[0]];
                const name = object[Object.keys(object)[1]];
                const selectedIds = selectedItems.map(
                  (item) => item[Object.keys(item)[0]]
                );

                if (typeof onMultiSelect !== 'function') {
                  return (
                    <div
                      key={id}
                      className={styles.optionItem}
                      onClick={() => selectItem(object)}
                      onKeyDown={() => selectItem(object)}
                      role="presentation"
                    >
                      {name}
                    </div>
                  );
                }

                return (
                  <div
                    key={id}
                    className={styles.optionItem}
                    style={{
                      cursor: typeof onMultiSelect === 'function' && 'default',
                    }}
                    role="presentation"
                  >
                    <div style={{ marginTop: '4px' }}>
                      <Checkbox
                        active={selectedIds.includes(id)}
                        label={name}
                        onCheck={() => selectItem(object)}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={styles.noResult}> No match found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

SelectComponent.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  onSelect: PropTypes.func,
  onMultiSelect: PropTypes.func,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  withSearch: PropTypes.bool,
  buttonStyle: PropTypes.shape(),
  predefinedSelected: PropTypes.arrayOf(),
  disabledStyle2: PropTypes.shape(),
  optionStyle: PropTypes.shape(),
};

SelectComponent.defaultProps = {
  label: '',
  list: null,
  onSelect: null,
  onMultiSelect: null,
  width: '70px',
  disabled: false,
  withSearch: false,
  buttonStyle: {},
  predefinedSelected: [],
  disabledStyle2: {},
  optionStyle: {},
};

export default SelectComponent;
