import { HttpMethods, APIContexts } from 'enums';
import { apiRequestHandler, apiHost } from '.';

const apiContext = `${APIContexts.PLAYLIST}/`;

export const CreateChannelArchitectureAPI = ({ requestBody, onSuccess, ...rest }) => {
  const api = 'CreateChannelArchitectureTemplate';
  const endpoint = `${apiHost}${apiContext}${api}`;

  apiRequestHandler({
    endpoint,
    method: HttpMethods.POST,
    payload: requestBody,
    onSuccess: (res) => onSuccess(res[0]),
    ...rest,
  });
};

export const UpdateChannelArchitectureAPI = ({ requestBody, onSuccess, ...rest }) => {
  const api = 'EditChannelArchitectureTemplate';
  const endpoint = `${apiHost}${apiContext}${api}`;

  apiRequestHandler({
    endpoint,
    method: HttpMethods.POST,
    payload: requestBody,
    onSuccess: (res) => onSuccess(res[0]),
    ...rest,
  });
};

export const DeleteChannelArchitectureAPI = ({ templateID, onSuccess, ...rest }) => {
  const api = 'DeleteChannelArchitectureTemplate';
  const endpoint = `${apiHost}${apiContext}${api}/${templateID}`;

  apiRequestHandler({
    endpoint,
    method: HttpMethods.DELETE,
    onSuccess: (res) => onSuccess(res[0]),
    ...rest,
  });
};
