import React from 'react'
import { GetBreakChannelTypeAPI } from 'api'
import { DataGrid } from 'op2mise-react-widgets';
import Button from 'shared/components/button/Button';
import Input from 'shared/components/input/Input';
import Switch from 'shared/components/switch/Switch';
import { uploadFileIcon } from '../../assets/index';
import './BreakPatterns.css';

const BreakPatternEditor = ({ mode, onClose, onImport, selectedRow }) => {
    const [versionName, setVersionName] = React.useState(selectedRow.version);
    const [status, setStatus] = React.useState(selectedRow.status === 'Active');
    const [versionData, setVersionData] = React.useState([]);
    // Input properties
    const props = {
        label: 'Version Name',
        labelStyle: {
            fontWeight: 'bold',
            color: '#6D6D73'
        },
        name: 'versionName',
        type: 'text',
        placeholder: 'Enter version Name',
        value: versionName,
        errorMessage: '',
        maxLength: 99,
        isRequired: true,
        width: '40%',
        onChange: (e) => {
            setVersionName(e.target.value);
        },
        onFocus: () => { },
        onBlur: () => { },
        onClick: () => {},
        disabled: false,
        pattern: '',
        style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '20px',
        },
        textAlign: 'left',
    };

    // Switch properties
    const switchProps = React.useMemo(() => ({
        label: 'Active',
        labelStyle: {
            fontWeight: 'bold',
            color: '#6D6D73'
        },
        text: ['No', 'Yes'],
        active: !status, 
        disabled: false,
        onSwitch: (e) => { setStatus(!e) },
        blurredOnInactive: false,
    }), [status, selectedRow.status]);

    // Column definitions for the data grid
    const columnDefinitions = React.useMemo(
        () => [
            {
                field: 'type',
                headerName: 'Type',
                headerTooltip: 'Type',
                filterParams: {
                  valueFormatter: (params) => {
                    return params.value.charAt(0).toUpperCase() + params.value.slice(1);
                  },
                },
                valueFormatter: (params) => {
                  return params.value.charAt(0).toUpperCase() + params.value.slice(1);
                },
            },
            {
                field: 'slotPosition',
                headerName: 'Slot Position',
                headerTooltip: 'Slot Position',
              },
              {
                field: 'optional',
                headerName: 'Optional',
                headerTooltip: 'Optional',
                cellDataType: 'text',
                filterParams: {
                   valueFormatter: (params) => params.value ? 'Yes' : 'No',
                  },
                  valueFormatter: (params) => params.value ? 'Yes' : 'No'
                },
                {
                  field: 'position',
                  headerName: 'Position',
                  headerTooltip: 'Position',
                  sort: 'asc',
                  cellStyle: { textAlign: 'right' },
            },
            {
                field: 'selection',
                headerName: 'Selection',
                headerTooltip: 'Selection',
                filterParams: {
                  valueFormatter: (params) => {
                    return params.value.charAt(0).toUpperCase() + params.value.slice(1);
                  },
                },
                valueFormatter: (params) => {
                  return params.value.charAt(0).toUpperCase() + params.value.slice(1);
                },
            },
            {
                field: 'variable',
                headerName: 'Variable',
                headerTooltip: 'Variable',
                cellDataType: 'text',
                filterParams: {
                  valueFormatter: (params) => params.value ? 'Yes' : 'No',
                },
                valueFormatter: (params) => params.value ? 'Yes' : 'No',
            },
            {
              field: 'assetID',
              headerName: 'Asset',
              headerTooltip: 'Asset',
            },
            {
              field: 'eventName',
              headerName: 'Event',
              headerTooltip: 'Event',
            },
            {
              field: 'priority',
              headerName: 'Priority',
              headerTooltip: 'Proirity',
              cellStyle: { textAlign: 'right' },
            },
        ],
        []
    );

    // Returns all fields from columnDefinition except the action column
    const headerFields = columnDefinitions
        .filter((column) => column.field !== 'action')
        .map((column) => column.field);

    const fetchChannelTemplate = (templateId) => {
      GetBreakChannelTypeAPI({
        queryParams: {breakChannelTemplateId: templateId},
        onSuccess: (res) => {
          setVersionData(res);
        }
      });
    };

    React.useEffect(() =>{
      if (selectedRow.id) {
        fetchChannelTemplate(selectedRow.id);
      }
    }, [selectedRow]);

    return (
        <div className='break-editor-wrapper'>
            <div className='break-editor-header'>
                <Input {...props} />
                <Switch {...switchProps} />
                <img
                    src={uploadFileIcon}
                    alt=""
                    title="Import"
                    onClick={() => { }}
                    style={{
                        cursor: 'pointer',
                    }}
                />
            </div>
            <DataGrid
                columns={columnDefinitions}
                customVerbiage="No break patterns found"
                enableMultipleRowSelection={false}
                gridHeightBuffer={345}
                resizable
                rows={versionData}
                sharedColumnProperties={{
                    // cellStyle: { fields: headerFields, value: { textAlign: 'left' } },
                    flex: { fields: headerFields, value: 1 },
                    sortable: { fields: headerFields,  value: true },
                    filter: { fields: headerFields, value: true },
                }}
                showAlternativeRowHighlight
                suppressRowClickSelection={true}
            />
            <div className='break-editor-footer'>
                <Button
                    text='Close'
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: '#6d6d73',
                        }}
                    onClick={() => onClose('view')} />
                <Button
                    text='Save'
                    onClick={() => { }}
                    disabled={!versionName} />
            </div>
        </div>
    )
}

export default BreakPatternEditor