/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable indent */
import React from 'react';
import { Scheduler } from 'op2mise-react-widgets';
import { Internationalization } from '@syncfusion/ej2-base';
import moment from 'moment';
import { ImportMinutageArchitectureAPI, GetMinutageArchitectureAPI } from "api";
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import { formatDateWithMoment } from 'utils';
import { ImportComponent } from './modals/ImportComponent';
import data from './ca-minutage.json'
import { getMondayOfTheWeek } from 'utils';
import { addDaysToDate } from 'utils';

export function CAMinutage({ schedulerProps, channelInfo, calculateDates }) {
  const instance = new Internationalization();

  const [schedules, setSchedules] = React.useState([{}]);
  const [openForm, setOpenForm] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const { channelId, channelName } = channelInfo;

  // Getting date range of today and tomorrow
  // NOTE: Use addDaysToDate from date.utils.js
  const tomorrow = moment()
    .add(1, 'days')
    .toDate();

  const period = `${formatDateWithMoment(
    new Date().toDateString(),
  )} - ${formatDateWithMoment(tomorrow)}`;

  // Date Header Template
  const dateHeaderTemplate = (args) => {
    const day = instance.formatDate(args.date, { skeleton: 'E' });
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className="e-header-day"
          style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
        >
          {day}
        </div>
      </div>
    );
  };

  const eventFields = (args) => ({
    Minutage: args.minutage,
    Id: args.id,
    Subject: args.minutage.toFixed(2),
    ProgramType: args.programmeType,
    StartTime: calculateDates(args.dayOfWeek, 1, args.startTime, args.endTime, { startDate: getMondayOfTheWeek(new Date()), endDate: addDaysToDate(getMondayOfTheWeek(new Date()), 6) })['startTime'],
    EndTime: calculateDates(args.dayOfWeek, 1, args.startTime, args.endTime, { startDate: getMondayOfTheWeek(new Date()), endDate: addDaysToDate(getMondayOfTheWeek(new Date()), 6) })['endTime'],
  });

  const eventTemplate = (args) => {
    const { Minutage } = args;

    return (
      <div
        className={`e-schedule-custom-event `}
      >
        <div className="e-schedule-custom-event-title e-schedule-minutage-title">
          {Minutage.toFixed(2)}
        </div>
      </div>
    );
  };

  const tooltipTemplate = (args) => {
    return (
      <div className='tooltip-wrap'>
        <div className='content-area'>
          <div className='event-name'>
            <span>{args.Minutage.toFixed(2)}</span>
          </div>
        </div>
      </div>
    );
  }

  const cleanOutInvalidMinutages = (data) => {
    return data.filter((schedule) => schedule.minutage !== null && schedule.minutage !== undefined && schedule.minutage !== 0);
  }

  const props = React.useMemo(
    () => ({
      ...schedulerProps,
      dataSource: schedules,
      eventLookupList: 'minutage',
      loading: isLoading,
      suppressExporting: true,
      suppressQuickInfo: true,
      suppressContextMenu: true,
      suppressTooltip: false,
      dateHeaderTemplate,
      eventTemplate,
      eventFields,
      onImport: () => {
        // Put your import logic here
        setOpenForm('IMPORT');
      },
      settings: ['SLOT DURATION', 'SLOT INTERVAL'],
      customTooltipTemplate: tooltipTemplate,
      timeoutDelay: 800,

    }),
    [schedules, channelId],
  );
  
  React.useEffect(() => {
    if (channelId || isDirty) {
      GetMinutageArchitectureAPI({
        queryParams: { channelId },
        onSuccess: (response) => {
          const validMinutages = cleanOutInvalidMinutages(response);
          setSchedules({minutage: [...validMinutages]});
          setIsDirty(false);
        },
        setLoader: setIsLoading,
      });
    }
  }, [channelId, isDirty]);

  return (
    <>
      {' '}
      {openForm === 'IMPORT' && (
        <ImportComponent
          closeModal={() => setOpenForm('')}
          setIsDirty={setIsDirty}
          setIsLoading={setIsLoading}
          scheduleInfo={{ ...channelInfo, period }}
          schedulerData={{
            scheduleInfo: {
              ...channelInfo,
              period,
            },
          }}
          sampleTemplate="minutageImportTemplate"
          handleOnImportApi={ImportMinutageArchitectureAPI}
          importResultColumns={[{
              field: 'dayOfweek',
              headerText: 'Day of Week',
            }, {
              field: 'startTime',
              headerText: 'Start Time',
            }, {
              field: 'endTime',
              headerText: 'End Time',
            }, {
              field: 'minutage',
              headerText: 'Minutage',
            }]}
          importResultFileTitle="minutage_import_result"
        />
      )}
      {isLoading
        ? <div style={{ height: 'calc(100vh - 100px)' }}><BootstrapSpinner /></div>
        : channelId === 0 && !channelName ? null : <Scheduler {...props} />}
    </>
  );
}

export default CAMinutage;
