import React, { useState, useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import SubmittedScheduleBannerComponent from './submitted-schedule-banner/SubmittedScheduleBannerComponent';
import './full-calendar.css';
import styles from '../Submission.module.css';
import SubmissionService from '../SubmissionService';
import DateService from '../../../core/date.service';

function SubmittedScheduleFullComponent() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data } = state;
  const [scheduleID, setScheduleID] = useState(1);
  const [schedules, setSchedules] = useState([]);
  const [startDate, setStartDate] = useState();
  const calendarRef = useRef();
  const [scheduleInfo, setSCheduleInfo] = useState({
    channelID: 0,
    channelName: '',
    month: '',
    year: '',
  });
  const [disabledStatistics, setDisabledStatistics] = useState(true);
  const [disabledExport, setDisabledExport] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [scheduleScores, setScheduleScores] = useState('');
  const [validRange, setValidRange] = useState({});
  const [loading, setLoading] = useState(true);
  const [organisation, setOrganisation] = useState('');
  // const moment = require('moment');
  const hiddenDays = []; // [0, 1, 2]

  const getGeneratedScheduleDetails = (id) => {
    setSchedules(id);
    SubmissionService.getSubmissionDetails(id).then((response) => {
      setSchedules(response.data.scheduleDetails);
      setStartDate(response.data.startDate);
      setSCheduleInfo({
        channelID: response.data.channelID,
        channelName: response.data.channelName,
        month: response.data.month,
        year: response.data.year,
      });
      setValidRange({
        start: response.data.startDate,
        end: response.data.endDate,
      });
      setScheduleScores(response.data.scoreLabel);
      setOrganisation(response.data.organisationName);
      setDisabledExport(response.data.disableExport);
      setDisabledStatistics(response.data.disableStatistics);
      setLoading(false);
      if (calendarRef != null) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(response.data.startDate);
      }
    });
  };

  const renderEventContent = (eventInfo) => {
    if (eventInfo.event.allDay) {
      const scoreDetails = eventInfo.event.extendedProps;
      return (
        <div style={{ padding: '10px' }}>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>Weighted:</b>
            <b className={styles.allDayScore}>{scoreDetails.weighted}</b>
          </div>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>Unweighted:</b>
            <b className={styles.allDayScore}>{scoreDetails.unweighted}</b>
          </div>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>US Box Office:</b>
            <b className={styles.allDayScore}>{scoreDetails.usBoxOffice}</b>
          </div>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>UK Box Office:</b>
            <b className={styles.allDayScore}>{scoreDetails.ukBoxOffice}</b>
          </div>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>Youth of Asset:</b>
            <b className={styles.allDayScore}>{scoreDetails.youth}</b>
          </div>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>Average Break:</b>
            <b className={styles.allDayScore}>{scoreDetails.break}</b>
          </div>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>Strength of Primetime:</b>
            <b className={styles.allDayScore}>{scoreDetails.primetime}</b>
          </div>
          <div style={{ width: '100%' }}>
            <b className={styles.allDayText}>Neatness:</b>
            <b className={styles.allDayScore}>{scoreDetails.neatness}</b>
          </div>
        </div>
      );
    }
    const { title } = eventInfo.event;
    const { grade } = eventInfo.event.extendedProps;
    const { certificate } = eventInfo.event.extendedProps;
    const { pin } = eventInfo.event.extendedProps;
    const timeStamp = DateService.toTimeFormat(eventInfo.event.start);
    const { duration } = eventInfo.event.extendedProps;
    const { genre } = eventInfo.event.extendedProps;
    const durationInt = (eventInfo.event.end.getTime() - eventInfo.event.start.getTime())
      / 1000
      / 60
      / 60;
    return (
      <div
        title={`${timeStamp} (${duration}) ${genre}\n${title}\n${grade}\n${certificate}`}
        className={styles.divParent}
      >
        <div className={styles.divChild}>
          <small
            style={{
              color: '#6D6D73',
              fontSize: '12px',
              width: '150px',
              // textOverflow: 'ellipsis',
              // whiteSpace: 'nowrap',
            }}
          />
          <small
            className={
              durationInt >= 1
                ? styles.lblTimeStampCalendar
                : styles.lblTimeStampCalendarNoWrap
            }
          >
            {timeStamp}
            {' '}
            (
            {duration}
            )
          </small>
          <br />
          <div style={{ color: '#0c212b' }} className={styles.lblTitleCalendar}>
            {title}
          </div>
          <small
            style={{ color: '#787E82', fontSize: '12px', display: 'block' }}
          >
            {grade}
            ,
            {certificate}
            ,
            {pin}
          </small>
        </div>
      </div>
    );
  };

  const callbackSubmission = () => {
    navigate(`${process.env.REACT_APP_SUBFOLDER}/submission`);
  };

  const handleExport = () => {
    if (!isExporting) {
      const queryString = `?SubmissionId=${scheduleID}&ChannelId=${scheduleInfo.channelID}`;
      setIsExporting(true);
      SubmissionService.exportSubmissions(queryString)
        .then((response) => {
          const blob = new Blob([response.data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(
            blob,
            `${organisation}_op2mise_submitted_schedule_export_${
              scheduleInfo.channelName
            }_${scheduleInfo.month}_${
              scheduleInfo.year
            }_${DateService.toExportFormat(new Date())}.xlsx`,
          );
        })
        .finally(() => {
          setIsExporting(false);
        });
    }
  };

  const handleExportStatistics = () => {
    if (!isExporting) {
      const queryString = `?SubmissionId=${scheduleID}&ChannelId=${scheduleInfo.channelID}`;
      setIsExporting(true);
      SubmissionService.exportStatistics(queryString)
        .then((response) => {
          const blob = new Blob([response.data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(
            blob,
            `${organisation}_op2mise_generated_statistics_export_${
              scheduleInfo.channelName
            }_${scheduleInfo.month}_${
              scheduleInfo.year
            }_${DateService.toExportFormat(new Date())}.xlsx`,
          );
        })
        .finally(() => {
          setIsExporting(false);
        });
    }
  };

  useEffect(() => {
    setScheduleID(data.id);
    getGeneratedScheduleDetails(data.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <SubmittedScheduleBannerComponent
        componentName="Submissions"
        scheduleDetails={scheduleInfo}
      />
      <hr style={{ background: '#c8c8c8' }} />
      {loading ? (
        <div
          className={`${styles.loadingHeight}`}
          style={{
            fontSize: '50px',
            color: '#13ad83',
            backgroundColor: 'transparent',
            position: 'relative',
          }}
        >
          <i
            className="fas fa-circle-notch fa-spin mr-1"
            style={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
              position: 'absolute',
              height: '50px',
              width: '50px',
            }}
          />
        </div>
      ) : (
        <>
          <small
            className={styles.divScheduleScores}
            title={`ID ${scheduleID} ${scheduleScores}`}
          >
            <b>
              ID
              {scheduleID}
            </b>
            {' '}
            {scheduleScores}
          </small>

          <FullCalendar
            ref={calendarRef}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              momentPlugin,
            ]}
            initialDate={startDate}
            initialView="timeGridWeek"
            // height={'calc(100vh - 150px)'}
            contentHeight="calc(100vh - 230px)"
            allDayDefault={false}
            editable={false}
            selectable={false}
            selectMirror
            dayMaxEvents
            // weekends={false}
            slotMinTime="06:00:00"
            slotMaxTime="29:59:00"
            // allDayText={'ID ' + scheduleID}
            // eventContent={renderEventContent}
            // events={schedules}
            // validRange={validRange}
            allDaySlot={false}
            // allDayText={'ID ' + scheduleID}
            eventContent={renderEventContent}
            events={schedules}
            validRange={validRange}
            slotLabelFormat={['H:mm']}
            // prettier-ignore
            dayHeaderContent={(args) => moment(args.date).format('ddd DD/MM/YYYY')}
            hiddenDays={hiddenDays}
            // datesSet={function(dateInfo) {
            //   setTimeout(() => {
            //     // setCurrentMonth(calendarRef.current.getApi().getDate().toString());
            //   }, 50);
            // }}
          />
          <div style={{ marginTop: '15px', float: 'right' }}>
            <button
              className={styles.cancelButton}
              onClick={callbackSubmission}
              type="button"
            >
              Close
            </button>
            <button
              className={styles.saveButton}
              style={{ marginLeft: '10px' }}
              onClick={handleExportStatistics}
              disabled={disabledStatistics}
              type="button"
            >
              Statistics
            </button>
            <button
              className={styles.saveButton}
              style={{ marginLeft: '10px' }}
              onClick={handleExport}
              disabled={disabledExport}
              type="button"
            >
              Export
            </button>
          </div>
        </>
      )}
    </div>
  );
}
export default SubmittedScheduleFullComponent;
