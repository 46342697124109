import React, { useState, useRef, useEffect } from "react";
import ScheduleService from "components/schedule/ScheduleService";
import DateService from "core/date.service";
import AuthService from "core/auth.service";
import ImportFileModalComponent from "components/schedule/react-scheduler/forms/import/ImportFileModalComponent";
import ImportLoadingModalComponent from "components/schedule/react-scheduler/forms/import/ImportLoadingModalComponent";
import ImportConfirmModalComponent from "components/schedule/react-scheduler/forms/import/ImportConfirmModalComponent";
import { queryParams } from "@syncfusion/ej2-base";
import ImportConfirmationModalComponent from "components/channel-profiles/business-rules/modals/ImportConfirmationModalComponent";
import { close } from "@sentry/react";
import ImportResultModalComponent from "./ImportResultModalComponent";
import styles from "../../schedule/react-scheduler/forms/import/ImportSlots.module.css";

export function ImportComponent({
  closeModal,
  setScheduleData,
  scheduledata =[],
  clockStart = 0,
  scheduleInfo,
  schedulerData,
  setIsDirty,
  sampleTemplate,
  handleOnImportApi,
  importResultColumns,
  importResultFileTitle,

}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [headerData, setHeaderData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadedList, setUploadedList] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [uploadedSlots, setUploadedSlots] = useState([]);
  const { channelId } = { ...scheduleInfo };

  const loadingHeaderComponent = (
    <div className={styles.divHeader}>
      Importing and validating slots from{" "}
      <b>{selectedFile && selectedFile.name}</b>
    </div>
  );
  
  const isInvalid = (value) => {
    return value === null || value === undefined || value == 0 || value.length === 0;
  }

  // Only for Grading and Minutage
  const cleanOutInvalidSlots = (data) => {
   data.map((schedule) => {
      if(sampleTemplate.includes('grade')) {
        if(isInvalid(schedule.grade)) {
          schedule.imported = false;
          schedule.result = "Invalid grade input. Must be between 1 and 10";
        }
      } else if(sampleTemplate.includes('minutage')){
        if(isInvalid(schedule.minutage)) {
          schedule.imported = false;
          schedule.result = "Invalid minutage input.";
        }
        schedule.minutage = parseFloat(schedule.minutage).toFixed(2);
      } 
    })
  }

  const uploadFile = async (file, fileExtension, overwrite) => {
      setIsLoading(true);
      try {
        const response = await handleOnImportApi(channelId, file);
        if (response) {
          if ((response.data.gradeArchitectureExist 
            ?? response.data.channelArchitectureExist 
            ?? response.data.gradeMinutageArchitectureExist) && !overwrite) {
            setConfirmModal(true);
            setIsLoading(false);
            setIsDirty(false);
            setUploadedList()
          } else {
            cleanOutInvalidSlots(response.data.gradeArchitectureModel 
              ?? response.data.channelArchitectureModel 
              ?? response.data.gradeMinutageArchitectureModel)
            setUploadedList(response.data.gradeArchitectureModel 
              ?? response.data.channelArchitectureModel 
              ?? response.data.gradeMinutageArchitectureModel);
            setIsDirty(true);
            setUploadSuccess(true);
            setIsLoading(false);
          }
        }
      } catch (err) {
        if (err.message.includes("400")) {
          setErrorMessage(
            "Invalid file format. Please use files in the suggested format above (see sample template)"
          );
        } else if(err.message.includes("500")) {
        setErrorMessage("Error uploading file. Please try again later");
        console.error(err);
        }
        setConfirmModal(false);
        setIsDirty(false);
        setUploadSuccess(false);
        setIsLoading(false);
      }
    }

  const handleOnImportFile = () => {
    const acceptableFiles = ["xlsx", "xls", "csv"];
    const fileExtension = selectedFile.name.split(".").pop();
    if (acceptableFiles.includes(fileExtension)) {
      if (scheduledata.length > 0) setConfirmModal(true);
      else uploadFile(selectedFile, fileExtension, false);
    } else {
      setErrorMessage(
        "Uploaded file type is not supported. Please use files in the following format (xlsx, csv)"
      );
    }
  };

  const handleOverwrite = () => {
    const fileExtension = selectedFile.name.split(".").pop();
    uploadFile(selectedFile, fileExtension, true);
  };

  return isLoading ? (
    <ImportLoadingModalComponent headerComponent={loadingHeaderComponent} />
  ) : uploadSuccess ? (
    <ImportResultModalComponent
      uploadedList={uploadedList}
      closeModal={closeModal}
      scheduleInfo={scheduleInfo}
      importResultColumns={importResultColumns}
      importResultFileTitle={importResultFileTitle}
    />
  ) : confirmModal ? (
    <ImportConfirmationModalComponent
      show={confirmModal}
      onHide={closeModal}
      onProceed={handleOverwrite}
      message="A list already exists for this channel. Importing overwrites all existing data for this channel."
    />
  ) : (
    <ImportFileModalComponent
      closeModal={closeModal}
      selectedFile={selectedFile}
      setSelectedFile={(e) => {
        setSelectedFile(e);
        setErrorMessage("");
      }}
      handleOnImportFile={handleOnImportFile}
      errorMessage={errorMessage}
      setErrorMessage={(e) => setErrorMessage(e)}
      sampleTemplate={sampleTemplate}
    />
  );
}
